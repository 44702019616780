const idpConfigMap = {
  amsterdamumc: {
    externalProviderId: 'saml.a05ce8dc6df2966ffd0cf098d329e21dd',
  },
  windesheim: {
    externalProviderId: 'saml.a7c588283e83a906442b0591b722db36b',
  },
  // Add more mappings as needed
};

export default idpConfigMap;
